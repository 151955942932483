import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import { RichText } from 'prismic-reactjs'
import Layout from '../components/layouts'
import htmlSerializer from '../utils/htmlSerializer'

const Page = ({data}) => {
  if (!data) return null
  const document = data.allPrismicWritingItem.edges[0].node

    return (
      <Layout>
        <SEO title={RichText.asText(document.data.title)} />
        <div className="mt-5">

          <div className="page__content">
            <div className="hero__media mx-auto mb-3">
            </div>
          </div>

          <div className="page__content">
            <div class="copy__body__center">
              <div>
                <div
                  className="eyebrow">Writing</div>
                <h1
                  className="display__title mb-5">
                  {RichText.asText(document.data.title)}
                </h1>
                <div
                  className="description mb-5">
                  {RichText.asText(document.data.description)}
                </div>
              </div>
            </div>
          </div>

        </div>
      </Layout>
    )
  }

// Query for the Blog Post content in Prismic
export const query = graphql`
query WritingQuery($uid: String) {
  allPrismicWritingItem(filter: {uid: {eq: $uid}}) {
    edges {
      node {
        data {
          description {
            type
            text
          }
          thumbnail {
            url
          }
          title {
            type
            text
          }
          link {
            link_type
            url
          }
        }
        url
        uid
        id
        type
      }
    }
  }
}
`

export default Page
